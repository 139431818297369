import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { store } from '@/store/index';
import vuetify from './plugins/vuetify';
import { ValidationProvider, extend } from 'vee-validate';
import { required, email, regex } from 'vee-validate/dist/rules';
import i18n from '@/plugins/i18n';
import Meta from 'vue-meta';
import VueSocialSharing from 'vue-social-sharing';
import VueCookies from 'vue-cookies-ts';
import VueHead from 'vue-head';
import VueSocialChat from 'vue-social-chat'

// FontAwesomeIcon
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCoins,
  faPercentage,
  faTools,
  faEdit,
  faChartLine,
  faHourglass,
  faHome,
  faCalculator,
  faGlobe,
  faDna,
  faFileWord,
  faBook,
  faBookReader,
  faNewspaper,
  faCommentDots,
  faHotel,
  faBookOpen,
  faLandmark,
  faCogs,
  faFilePdf,
  faBalanceScale,
  faSync,
  faUserGraduate,
  faComments
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faCoins,
  faCheck,
  faPercentage,
  faTools,
  faEdit,
  faChartLine,
  faHourglass,
  faHome,
  faCalculator,
  faGlobe,
  faDna,
  faFileWord,
  faBook,
  faBookReader,
  faNewspaper,
  faCommentDots,
  faHotel,
  faBookOpen,
  faLandmark,
  faCogs,
  faFilePdf,
  faBalanceScale,
  faSync,
  faUserGraduate,
  faComments
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

extend('regexCodiceFiscale', {
  ...regex,
  message: 'Codice fiscale non valido',
});

extend('regexPartitaIva', {
  ...regex,
  message: 'Partita IVA non valida',
});

extend('regexNumero', {
  ...regex,
  message: 'Numero non valido',
});

extend('required', {
  ...required,
  message: 'Obbligatorio',
});

extend('requiredMin', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length'],
  message: 'Il testo deve contenere almeno {length} caratteri',
});

extend('email', {
  ...email,
  message: 'Email errata',
});

Vue.component('ValidationProvider', ValidationProvider);

Vue.use(Meta);
Vue.use(VueSocialSharing);
Vue.use(VueCookies);
Vue.use(VueHead);
Vue.use(VueSocialChat)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
