import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    crmInfo: undefined,
    searchData: undefined,
    searchResults: undefined,
    dossierDocument: undefined,
  },
  mutations: {
    crmInfo(state, crmInfo) {
      state.crmInfo = crmInfo;
    },
    searchData(state, searchData) {
      state.searchData = searchData;
    },
    searchResults(state, searchResults) {
      state.searchResults = searchResults;
    },
    dossierDocument(state, dossierDocument) {
      state.dossierDocument = dossierDocument;
    },
  },
  actions: {
    crmInfo(context, crmInfo) {
      context.commit('crmInfo', crmInfo);
    },
    searchData(context, searchData) {
      context.commit('searchData', searchData);
    },
    searchResults(context, searchResults) {
      context.commit('searchResults', searchResults);
    },
    dossierDocument(context, dossierDocument) {
      context.commit('dossierDocument', dossierDocument);
    },
  },
  getters: {
    crmInfo: (state) => {
      return state.crmInfo;
    },
    searchData: (state) => {
      return state.searchData;
    },
    searchResults: (state) => {
      return state.searchResults;
    },
    dossierDocument: (state) => {
      return state.dossierDocument;
    },
  },
});
