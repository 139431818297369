import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'it': {},
    'en': {}
};

export default new VueI18n({
    locale: 'it',
    fallbackLocale: 'en',
    messages,
});