interface PaginationProps {
  page: number;
  total: number;
  perPage: number;
  visible: number;
}

export class Pagination {

  private props: PaginationProps

  constructor(props: PaginationProps) {
    this.props = props
  }

  // GETTERs and SETTERs
  get page(): number {
    return this.props.page
  }
  set page(page: number) {
    this.props.page = page
  }
  get total(): number {
    return this.props.total
  }
  set total(total: number) {
    this.props.total = total
  }
  get perPage(): number {
    return this.props.perPage
  }
  get visible(): number {
    return this.props.visible
  }
}
