import { BaseAnalysis, CassationSentenceAnalysis, MaximsAnalysis, SummarizationGenericAnalysis } from '@/models/ai/document.analysis.model';
import { AxiosError } from 'axios';

export class Utils {
  static async timeout(millis: number) {
    return new Promise((resolve) => setTimeout(resolve, millis));
  }

  static checkProfile(profiles: any, profile: string) {
    console.log("profile: " + profile)
    for (const p of profiles) {
      if (p.profile === profile) {
        return true;
      }
    }
    return false;
  }

  static b64DecodeUnicode(text: string): string {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(text), function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  static sort(prop: any, type: any) {
    return function (a: any, b: any) {
      if (type && type == 'asc') {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
      } else if (!type || type == 'desc') {
        if (a[prop] < b[prop]) {
          return 1;
        } else if (a[prop] > b[prop]) {
          return -1;
        }
      }
      return 0;
    };
  }

  static getImage(source: any): string {
    let imageSrc = source.image;
    if (!imageSrc || imageSrc == '') {
      imageSrc =
        process.env.VUE_APP_JURANEWS_BACKOFFICE_URL +
        '/images/placeholder/image-placeholder.jpeg';
    } else {
      imageSrc = process.env.VUE_APP_JURANEWS_BACKOFFICE_URL + '/' + imageSrc;
    }
    return imageSrc;
  }

  static extractContent(html: string): string {
    const span = document.createElement('span');
    span.innerHTML = html;
    return span.textContent || span.innerText;
  }

  static htmlToSave(divName: string) {
    const contentToPrint = document.getElementById(divName);
    let stylesHtml = '';
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }
    return (
      '<html><head>' +
      stylesHtml +
      '</head><body><img class="center" style="margin-top: 15px;" src="/img/logo.png" height="80" /><br/><hr class="solid">' +
      contentToPrint!.innerHTML +
      '</div></body></html>'
    );
  }

  static print(divName: string) {
    const contentToPrint = document.getElementById(divName)?.cloneNode(true);
    let elementToRemove = (contentToPrint as HTMLElement).querySelector('.NotPrintable');
    while (elementToRemove) {
      elementToRemove.remove();
      elementToRemove = (contentToPrint as HTMLElement).querySelector('.NotPrintable');
    }
    let stylesHtml = '';
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }

    if (contentToPrint) {
      const winPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      );

      if (winPrint) {
        winPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
    <script>
      function printDocument() {
          var content = document.getElementById('content');

          console.log(content)
          var printButton = document.querySelector('button');
          printButton.style.display = 'none';

          window.print();

          printButton.style.display = 'block';
      }
  </script>
  </head>
  <body>
    <button onclick="printDocument()" type="button" style="margin: 10px 10px" class="ml-3 mt-3 red-juranews relevation-0 rounded-0 text-center v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default" style="width: 130px;"><span class="v-btn__content">Stampa</span></button>
    <div id="content">
      <img class="center" style="margin-top: 15px;" src="/img/logo.png" height="80" />
      <br/>
      <hr class="solid">
      ${(contentToPrint as HTMLElement).innerHTML}
    </div>
  </body>
</html>`);
        winPrint.document.close();
        winPrint.focus();
      }
    }
  }

  static analysisHeaderHtml(analysis: BaseAnalysis, header: string): string {
    let html = `<div style="background:#eeeeee">`;

    html += `<table style="border:none;width:100%"><tr><td style="border:none;text-align:center;">` +
            `<div style="text-align:center;padding-top:10px;"><h2>` + header + `*</h2></div></td></tr></table>`
    return html
  }

  static cassationAnalysisToHtml(analysis: BaseAnalysis, inDoc = false): string {
    const cassationAnalysis = (analysis as CassationSentenceAnalysis).analysis;
    let html = inDoc ? this.analysisHeaderHtml(analysis, "SINTESI") : "";
    const paddingLR = inDoc ? "25px" : "5px";
    const paddingTB = inDoc ? "15px" : "0px";
    html += `<div style="padding-top: ` + paddingTB + `;padding-bottom: ` + paddingTB + `;padding-left:` + paddingLR + `;padding-right:` + paddingLR + `;font-family:'Book Antiqua';font-size:16px">`
    if (cassationAnalysis.fatti_concreti && cassationAnalysis.fatti_concreti.length > 0) {
      html += `<h4>Fatti Concreti</h4>`
      html += `<table style="border:none; width: 100%;"><tbody>`
      for (const fatto of cassationAnalysis.fatti_concreti) {
        html += `<tr><td style="text-align:justify;border:none;"><li>` + fatto + `</td></tr>`
      }
      html += `</tbody></table>`
    }
    if (cassationAnalysis.fatti_processuali && cassationAnalysis.fatti_processuali.length > 0) {
      html += `<h4 style="padding-top: 20px;">Fatti Processuali</h4>`
      html += `<table style="border:none; width: 100%;"><tbody>`
      for (const fatto of cassationAnalysis.fatti_processuali) {
        html += `<tr><td style="text-align:justify;border:none;"><li>` + fatto + `</td></tr>`
      }
      html += `</tbody></table>`
    }
    if (cassationAnalysis.motivi && cassationAnalysis.motivi.length > 0) {
      html += `<h4 style="padding-top: 20px;">Motivi Ammissibili</h4>`
      for (const motivo of cassationAnalysis.motivi) {
        html += `<div style="text-align:left;padding-top:10pt;font-family:'Book Antiqua">` + motivo.motivo + "</div>"
        html += `<table style="padding-top:15px;border:none; width: 100%;"><tbody>`
        html += `<tr><td style="text-align:left;border-bottom:1pt dashed #a6a6a6;;"><b>Ammissibile</b></td>` +
                `<td style="padding-left:10px;border-bottom:1pt dashed #a6a6a6;padding-bottom:4px;text-align:justify;">` + motivo.ammissibile + `</td></tr>`
        html += `<tr><td style="text-align:left;border-bottom:1pt dashed #a6a6a6;"><b>Fondato</b></td>` +
                `<td style="padding-left:10px;border-bottom:1pt dashed #a6a6a6;padding-bottom:4px;text-align:justify;">` + motivo.fondato + `</td></tr>`
        html += `<tr><td style="text-align:left;border-bottom:1pt dashed #a6a6a6;"><b>Ragione</b></td>` +
                `<td style="padding-left:10px;border-bottom:1pt dashed #a6a6a6;padding-bottom:4px;text-align:justify;">` + motivo.ragione + `</td></tr>`
        html += `<tr><td style="text-align:left;border-bottom:1pt dashed #a6a6a6;"><b>Fattispecie Concreta</b></td>` +
                `<td style="padding-left:10px;border-bottom:1pt dashed #a6a6a6;padding-bottom:4px;text-align:justify;">` + motivo.fattispecie_concreta + `</td></tr>`
        html += `<tr><td style="text-align:left;border-bottom:1pt dashed #a6a6a6;"><b>Fattispecie Astratta</b></td>` +
                `<td style="padding-left:10px;border-bottom:1pt dashed #a6a6a6;padding-bottom:4px;text-align:justify;">` + motivo.fattispecie_astratta + `</td></tr>`
        html += `<tr><td style="text-align:left;border-bottom:1pt dashed #a6a6a6;"><b>Decisione della Corte</b></td>` +
                `<td style="padding-left:10px;border-bottom:1pt dashed #a6a6a6;padding-bottom:4px;text-align:justify;font-family:'Book Antiqua">` + motivo.decisione_della_corte + `</td></tr>`
        html += `</tbody></table>`
      }
    }
    if (cassationAnalysis.decisione) {
      html += `<br><h4 style="padding-top: 10px;">Decisione finale</h4>`
      html += `<div style="text-align:left;padding-top:10pt;font-family:'Book Antiqua">` + cassationAnalysis.decisione + "</div>"
    }
    html += "</div>"
    html += inDoc ? `<p style="padding-bottom:10px;padding-left:` + paddingLR + `;font-family:'Book Antiqua">* Contenuto generato da JuraNews AI e ` + (analysis.verified ? `<span style="color:green">verificato</span>` : `<span style="color:red"><b>non verificato</b></span>`) + ` dalla redazione.</p>` : "";
    html += inDoc ? "" : "<hr>"
    html += '<div class="page-break" />';
    return html
  }

  static genericSummaryToHtml(analysis: BaseAnalysis, inDoc = false): string {
    const summarizationGenericAnalysis = (analysis as SummarizationGenericAnalysis).analysis;
    let html = inDoc ? this.analysisHeaderHtml(analysis, "SINTESI") : "";
    const paddingLR = inDoc ? "25px" : "5px";
    const paddingTB = inDoc ? "15px" : "0px";
    html += `<div style="padding-top: ` + paddingTB + `;padding-bottom: ` + paddingTB + `;padding-left:` + paddingLR + `;padding-right:` + paddingLR + `;font-family:'Book Antiqua';font-size:16px">`
    if (summarizationGenericAnalysis.summary) {
      html += `<h4>Sintesi</h4>`
      html += `<div>` + summarizationGenericAnalysis.summary + `</div>`
    }
    if (summarizationGenericAnalysis.decision) {
      html += `<h4>Decisione</h4>`
      html += `<div>` + summarizationGenericAnalysis.decision + `</div>`
    }
    html += "</div>"
    html += inDoc ? `<p style="padding-bottom:10px;padding-left:` + paddingLR + `;font-family:'Book Antiqua">* Contenuto generato da JuraNews AI e ` + (analysis.verified ? `<span style="color:green">verificato</span>` : `<span style="color:red"><b>non verificato</b></span>`) + ` dalla redazione.</p>` : "";
    html += inDoc ? "" : "<hr>"
    html += '<div class="page-break" />';
    return html
  }

  static maximsAnalysisToHtml(analysis: BaseAnalysis, inDoc = false): string {
    const maximsAnalysis = (analysis as MaximsAnalysis).analysis
    let html = inDoc ? this.analysisHeaderHtml(analysis, "MASSIME") : "";
    const paddingLR = inDoc ? "25px" : "5px";
    const paddingTB = inDoc ? "15px" : "0px";
    html += `<div style="padding-top: ` + paddingTB + `;padding-bottom: ` + paddingTB + `;padding-left:` + paddingLR + `;padding-right:` + paddingLR + `;font-family:'Book Antiqua';font-size:16px">`
    for (const maxim of maximsAnalysis) {
      html += `<table style="border:none; width: 100%;"><tbody>`
      html += `<tr><th style="text-align:left;border:none;">Titolo</th><td style="padding-left:25px;border:none;">` + maxim.title + `</td></tr>`
      html += `<tr><th style="text-align:left;border:none;">Massima</th><td style="padding-left:25px;border:none;">` + maxim.content + `</td></tr>`
      if (maxim.norms.length > 0) {
        html += `<tr><th style="text-align:left;border:none;">Norme</th><td style="padding-left:25px;border:none;">`
        for (const norm of maxim.norms) {
          html += `<div>` + norm + `</div>`
        }
        html += `</td></tr>`
      }
      html += "</tbody></table><hr>"
    }
    html += "</div>"
    html += inDoc ? `<p style="padding-bottom:10px;padding-left:` + paddingLR + `;">* Contenuto generato da JuraNews AI e ` + (analysis.verified ? `<span style="color:green;">verificato</span>` : `<span style="color:red;"><b>non verificato</b></span>`) + ` dalla redazione.</p>` : "";
    html += inDoc ? "" : "<hr>"
    html += '<div class="page-break" />';
    return html
  }

  static isAxiosError(error: any): error is AxiosError {
    return error.isAxiosError === true;
  }
}



