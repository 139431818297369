export class Service {
  serviceId: string;
  name: string;
  description: string;
  unitCost: number;
  stripeID: string;
  stripePriceID: string;
  productId: number;

  constructor(
    serviceId: string,
    name: string,
    description: string,
    unitCost: number,
    stripeID: string,
    stripePriceID: string,
    productId: number,
  ) {
    this.serviceId = serviceId;
    this.name = name;
    this.description = description;
    this.unitCost = unitCost;
    this.stripeID = stripeID;
    this.stripePriceID = stripePriceID;
    this.productId = productId;
  }
}
