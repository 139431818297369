
import { Component, Vue, Prop } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import { ValidationObserver } from "vee-validate";
import JnProgress from "@/views/common/Progress.vue";

@Component({
  components: { ValidationObserver, JnProgress },
})
export default class JnChangePassword extends Vue {
  @Prop({ required: true })
  type?: string;

  dialog = false;
  success = false;
  resetted = false;
  email = "";

  currentPassword = "";
  currentPasswordProgress = 0;
  password = "";
  passwordProgress = 0;
  passwordConfirm = "";
  passwordConfirmProgress = 0;
  showCurrentPassword = false;
  showPassword = false;
  showPasswordConfirm = false;
  regExPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

  alert = false;
  alertMessage = "";
  alertTimeout = 6000;
  loading = false;

  progress(password: string, passwordProgress: number) {
    passwordProgress = Math.min(80, password.length * 10);
    if (this.regExPassword.test(password)) {
      passwordProgress = passwordProgress + 20;
    }
    return passwordProgress;
  }

  get progressCurrentPassword(): number {
    this.currentPasswordProgress = this.progress(
      this.currentPassword,
      this.currentPasswordProgress
    );
    return this.currentPasswordProgress;
  }

  get progressPassword(): number {
    this.passwordProgress = this.progress(this.password, this.passwordProgress);
    return this.passwordProgress;
  }

  get progressPasswordConfirm(): number {
    this.passwordConfirmProgress = this.progress(
      this.passwordConfirm,
      this.passwordConfirmProgress
    );
    return this.passwordConfirmProgress;
  }

  get colorCurrentPassword() {
    if (this.currentPasswordProgress < 80) {
      return "error";
    } else if (
      this.currentPasswordProgress >= 80 &&
      this.currentPasswordProgress < 100
    ) {
      return "warning";
    } else {
      return "success";
    }
  }

  get colorPassword() {
    if (this.passwordProgress < 80) {
      return "error";
    } else if (this.passwordProgress >= 80 && this.passwordProgress < 100) {
      return "warning";
    } else {
      return "success";
    }
  }

  get colorPasswordConfirm() {
    if (!this.passwordMatching) {
      return "error";
    } else {
      return "success";
    }
  }

  get passwordMatching(): boolean {
    return this.password == this.passwordConfirm;
  }

  get passwordOk(): boolean {
    return this.passwordMatching && this.passwordFormatOk;
  }

  get passwordFormatOk() {
    const regularExpression =
      /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return this.regExPassword.test(this.password) && this.password.length >= 8;
  }

  cancel() {
    this.dialog = false;
  }

  get authenticated() {
    return this.$store.getters.crmInfo;
  }

  async reset() {
    const res = await JnService.resetPassword(this.email);
    console.log("res: " + JSON.stringify(res));
    this.resetted = true;
  }

  async change() {
    this.loading = true;
    if (this.authenticated) {
      this.email = this.$store.getters.crmInfo.user.username;
    }
    const res = await JnService.changePassword(
      this.email,
      this.currentPassword,
      this.password
    );
    if (res.code == 200) {
      this.success = true;
    } else {
      this.alert = true;
      this.alertMessage = res.data;
    }
    this.loading = false;
  }

  close() {
    this.currentPassword = "";
    this.currentPasswordProgress = 0;
    this.password = "";
    this.passwordProgress = 0;
    this.passwordConfirm = "";
    this.passwordConfirmProgress = 0;
    this.showCurrentPassword = false;
    this.showPassword = false;
    this.showPasswordConfirm = false;
    this.dialog = false;
    this.success = false;
  }
}
