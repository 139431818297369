import { uuid } from 'vue-uuid';

export class DossierDocument {
  id: string;
  name?: string;
  text?: string;
  children: DossierDocument[];
  isDossier: boolean;

  constructor(
    id: string,
    name: string | undefined,
    text: string | undefined,
    children: DossierDocument[],
    isDossier: boolean
  ) {
    this.id = id;
    this.name = name;
    this.text = text;
    this.children = children;
    this.isDossier = isDossier;
  }

  addDoc(doc: DossierDocument) {
    this.children?.push(doc);
  }

  removeDoc(doc: DossierDocument, children: DossierDocument[] | undefined) {
    if (children) {
      for (const c of children) {
        if (c.id == doc.id) {
          children.splice(children.indexOf(doc), 1);
        }
        this.removeDoc(doc, c.children);
      }
    }
  }

  getAllChildren(children: DossierDocument[]) {
    if (this.children) {
      for (const c of this.children) {
        if (!c.isDossier) {
          children.push(c);
        }
        c.getAllChildren(children);
      }
    }
    return children;
  }

  initFromJson(jsonObject: any) {
    this.id = jsonObject.id;
    this.name = jsonObject.name;
    this.isDossier = jsonObject.isDossier;
    // console.log(jsonObject.children);
    if (jsonObject.children) {
      Object.entries(jsonObject.children).forEach((entry) => {
        const dossierDocument = new DossierDocument(
          uuid.v1(),
          undefined,
          undefined,
          [],
          false
        );
        const [key, value] = entry;
        dossierDocument.initFromJson(value);
        this.addDoc(dossierDocument);
      });
    }
  }

  clone(): DossierDocument {
    return new DossierDocument(
      this.id,
      this.name,
      this.text,
      this.children,
      this.isDossier
    );
  }
}
