interface EventProps {
  id: number;
  name: string;
  title: string;
  start: string;
  end: string;
  idCategory: string;
  category: string;
  color?: string;
  shortDesc: string;
  desc: string;
}

export class Event {
  private props: EventProps;

  constructor(props: EventProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get id(): number {
    return this.props.id;
  }

  get name(): string {
    return this.props.name;
  }

  get start(): string {
    return this.props.start;
  }

  get end(): string {
    return this.props.end;
  }

  get idCategory(): string {
    return this.props.idCategory;
  }

  get category(): string {
    return this.props.category;
  }

  get shortDesc(): string {
    return this.props.shortDesc;
  }
  get desc(): string {
    return this.props.desc;
  }
  get title(): string {
    return this.props.title;
  }
  get color(): string | undefined {
    return this.props.color;
  }
}
