
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class JnProgress extends Vue {
  
  @Prop({ required: true, default: false })
  loading?: boolean;

}
