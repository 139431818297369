import { User } from '@/models/crm/user.model';
import { Purchase } from '@/models/crm/purchase.model';
import { Service } from '@/models/crm/service.model';

export class CrmInfo {
  user: User;
  purchase?: Purchase;
  service?: Service;

  constructor(
    user: User,
    purchase: Purchase | undefined,
    service: Service | undefined
  ) {
    this.user = user;
    this.purchase = purchase;
    this.service = service;
  }
}
