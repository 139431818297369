interface MenuProps {
  name: string;
  routeName: string;
  routePath: string;
  index: number;
  items: Menu[] | undefined;
  icon: string | undefined;
  tooltip: string | undefined;
}

export class Menu {
  private props: MenuProps;

  constructor(props: MenuProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get name(): string {
    return this.props.name;
  }

  get routeName(): string {
    return this.props.routeName;
  }

  get routePath(): string {
    return this.props.routePath;
  }

  get index(): number {
    return this.props.index;
  }

  get items(): Menu[] | undefined {
    return this.props.items;
  }

  get icon(): string | undefined {
    return this.props.icon;
  }

  get tooltip(): string | undefined {
    return this.props.tooltip;
  }
}
