interface TestataProps {
  id: string;
  name: string;
  title: string;
  description: string;
  image: string;
}

export class Testata {
  private props: TestataProps;

  constructor(props: TestataProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get id(): string {
    return this.props.id;
  }

  get name(): string {
    return this.props.name;
  }

  get title(): string {
    return this.props.title;
  }

  get description(): string {
    return this.props.description;
  }

  get image(): string {
    return this.props.image;
  }
}
