const Home = () => import('@/views/home/Home.vue')
const JnChiSiamo = () => import('@/views/chisiamo/ChiSiamo.vue')
const JnAreaTematicaInterna = () => import('@/views/areetematiche/AreaTematicaInterna.vue')
const JnMassimario = () => import('@/views/massimario/Massimario.vue')
const JnFormulario = () => import('@/views/formulario/Formulario.vue')
const JnPubblicazioni = () => import('@/views/pubblicazioni/Pubblicazioni.vue')
const JnHelp = () => import('@/views/common/Help.vue')
const JnAreaPersonale = () => import('@/views/profile/AreaPersonale.vue')
const JnLogin = () => import('@/views/auth/Login.vue')
const JnJuranewsDoc = () => import('@/views/common/JuranewsDoc.vue')
const JnJuranetDoc = () => import('@/views/common/JuranetDoc.vue')
const JnAgenda = () => import('@/views/agenda/Agenda.vue')
const JnPodcast = () => import('@/views/podcast/Podcast.vue')
const JnArchivio = () => import('@/views/archivio/Archivio.vue')
const JnVideo = () => import('@/views/video/Video.vue')
const JnNews = () => import('@/views/news/News.vue')
const JnBancaDati = () => import('@/views/bancadati/BancaDati.vue')
const JnCodici = () => import('@/views/codici/Codici.vue')
const JnRicercheOnDemand = () => import('@/views/ricercheondemand/RicercheOnDemand.vue')
const JnServizio = () => import('@/views/ricercheondemand/RicercheOnDemand.vue')
const JnRealTime = () => import('@/views/realtime/RealTime.vue')
const JnAbbonamentiAdmin = () => import('@/views/abbonamenti/AbbonamentiAdmin.vue')
const JnAbbonamentiUtente = () => import('@/views/abbonamenti/AbbonamentiUtente.vue')
const JnRichiestaTrial = () => import('@/views/abbonamenti/RichiestaTrial.vue')
const JnSottoscrizioneNewsletter = () => import('@/views/abbonamenti/SottoscrizioneNewsletter.vue')
const JnContatti = () => import('@/views/contatti/Contatti.vue')
const JnPrivacy = () => import('@/views/policies/Privacy.vue')
const JnCookie = () => import('@/views/policies/Cookie.vue')
const JnGeneralSearch = () => import('@/views/common/GeneralSearch.vue')
const JnAiChat = () => import('@/views/ai/Chat.vue')
const JnAiAnalysisReview = () => import('@/views/ai/AnalysisReview.vue')

import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import EventBus from '@/services/event.bus';
import { store } from '@/store/index';
import VueGtag from 'vue-gtag';
import { SearchTypes } from '@/models/enums.model';



Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/help',
    name: 'Help',
    component: JnHelp,
  },
  {
    path: '/loggedin',
    name: 'HomeLoggedIn',
    component: Home,
  },
  {
    path: '/chi-siamo',
    name: 'ChiSiamo',
    component: JnChiSiamo,
  },
  {
    path: '/aree-tematiche/:name',
    name: 'AreaTematicaInterna',
    props: true,
    component: JnAreaTematicaInterna,
  },
  {
    path: '/massimario',
    name: 'Massimario',
    props: true,
    component: JnMassimario,
  },
  {
    path: '/formulario',
    name: 'Formulario',
    props: true,
    component: JnFormulario,
  },
  {
    path: '/news',
    name: 'News',
    component: JnNews,
  },
  {
    path: '/banca-dati',
    name: 'BancaDati',
    component: JnBancaDati,
  },
  {
    path: '/codici',
    name: 'Codici',
    component: JnCodici,
  },
  {
    path: '/realtime',
    name: 'Realtime',
    component: JnRealTime,
  },
  {
    path: '/general-search',
    name: 'GeneralSearch',
    component: JnGeneralSearch,
  },
  {
    path: '/pubblicazioni',
    name: 'Pubblicazioni',
    component: JnPubblicazioni,
  },
  {
    path: '/video',
    name: 'Video & Podcast',
    component: JnVideo,
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: JnAgenda,
  },
  {
    path: '/archivio',
    name: 'Archivio',
    component: JnArchivio,
  },
  {
    path: '/podcast',
    name: 'Podcast',
    component: JnPodcast,
  },
  {
    path: '/ricerche-on-demand',
    name: 'RicercheOnDemand',
    component: JnRicercheOnDemand,
  },
  {
    path: '/docs/juranews/:id',
    name: 'JuranewsDoc',
    props: true,
    component: JnJuranewsDoc,
  },
  {
    path: '/docs/juranet/:id',
    name: 'JuranetDoc',
    props: true,
    component: JnJuranetDoc,
  },
  {
    path: '/docs/juranet/:id/:target',
    name: 'JuranetDocTarget',
    props: true,
    component: JnJuranetDoc,
  },
  {
    path: '/docs/juranet/:id/:target/:type',
    name: 'JuranetDocTargetType',
    props: true,
    component: JnJuranetDoc,
  },
  {
    path: '/area-personale',
    name: 'AreaPersonale',
    component: JnAreaPersonale,
  },
  {
    path: '/login',
    name: 'Login',
    component: JnLogin,
  },
  {
    path: '/servizi/ammortamento-mutui',
    name: 'ServizioAmmortamentoMutui',
    component: JnServizio,
  },
  {
    path: '/servizi/fattura-avvocato"',
    name: 'ServizioFatturaAvvocato',
    component: JnServizio,
  },
  {
    path: '/servizi/interessi-legali',
    name: 'ServizioInteressiLegali',
    component: JnServizio,
  },
  {
    path: '/servizi/interessi-mora',
    name: 'ServizioInteressiMora',
    component: JnServizio,
  },
  {
    path: '/servizi/cedolare-secca',
    name: 'ServizioCedolareSecca',
    component: JnServizio,
  },
  {
    path: '/servizi/danno-biologico-lieve',
    name: 'ServizioDannoBiologicoLieve',
    component: JnServizio,
  },
  {
    path: '/servizi/rivalutazione-monetaria',
    name: 'ServizioRivalutazioneMonetaria',
    component: JnServizio,
  },
  {
    path: '/servizi/assegno-mantenimento-figli',
    name: 'ServizioAssegnoMantenimentoFigli',
    component: JnServizio,
  },
  {
    path: '/servizi/assegno-mantenimento-ex-coniuge',
    name: 'ServizioAssegnoMantenimentoExConiuge',
    component: JnServizio,
  },
  {
    path: '/ai/chat',
    name: 'AiChat',
    component: JnAiChat,
  },
  {
    path: '/ai/review',
    name: 'AiAnalysisReview',
    component: JnAiAnalysisReview,
  },
  {
    path: '/abbonamenti-utente',
    name: 'AbbonamentiUtente',
    component: JnAbbonamentiUtente,
  },
  {
    path: '/abbonamenti',
    name: 'AbbonamentiAdmin',
    component: JnAbbonamentiAdmin,
  },
  {
    path: '/abbonamenti/:result',
    name: 'AbbonamentiAdminResult',
    component: JnAbbonamentiAdmin,
    props: true,
  },
  {
    path: '/abbonamenti/:result/:id',
    name: 'AbbonamentoAdminResult',
    component: JnAbbonamentiAdmin,
    props: true,
  },
  {
    path: '/contatti',
    name: 'Contatti',
    component: JnContatti,
  },
  {
    path: '/richiesta-trial',
    name: 'RichiestaTrial',
    component: JnRichiestaTrial,
  },
  {
    path: '/sottoscrizione-newsletter',
    name: 'SottoscrizioneNewsletter',
    component: JnSottoscrizioneNewsletter,
  },
  {
    path: '/policies/privacy',
    name: 'PrivacyPolicy',
    component: JnPrivacy,
  },
  {
    path: '/policies/cookie',
    name: 'CookiePolicy',
    component: JnCookie,
  },
  {
    path: 'https://www.linkedin.com/company/juranews',
    name: 'LinkedIn',
  },
  {
    path: 'https://www.facebook.com/JuraNews1/',
    name: 'Facebook',
  },
  {
    path: 'https://www.instagram.com/juranewsofficial/',
    name: 'Instagram',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

if (process.env.VUE_APP_FIREBASE_TRACKING) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID },
      appName: 'JuraNews - Portale',
      pageTrackerScreenviewEnabled: true,
      onReady() {
        console.log('VieGtag plugin configured!');
      },
    },
    router
  );
}

router.beforeEach((to, from, next) => {
  console.log(
    "Router.beforeEach: go to '" +
    to.fullPath +
    "' from '" +
    from.fullPath +
    "'"
  );
  if (to.name == 'Realtime') {
    EventBus.$emit('reset-real-time');
  }
  if (
    (to.name != 'GeneralSearch' || from.name == 'Realtime') &&
    !to.fullPath.startsWith('/doc')
  ) {
    EventBus.$emit('search-results', []);
    EventBus.$emit('search-data', undefined);
    store.commit('searchResults', []);
    console.log('Router.beforeEach: resetting search-data in store...');
  }
  if (to.name == 'GeneralSearch') {
    EventBus.$emit('reset-ricerca-global', []);
  }

  EventBus.$emit('check-spell', SearchTypes.ALL_HEADER);
  EventBus.$emit('checkAuthentication');
  next();
});

export default router;
