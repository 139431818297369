interface ProfileProps {
  id: string;
  seq: number;
  imageSrc: string;
  name: string;
  businessRole: string;
  curriculum: string;
  routePath: string;
}

export class Profile {

  private props: ProfileProps

  constructor(props: ProfileProps) {
    this.props = props
  }

  // GETTERs and SETTERs
  get id(): string {
    return this.props.id
  }

  get seq(): number {
    return this.props.seq
  }

  get imageSrc(): string {
    return this.props.imageSrc
  }

  get name(): string {
    return this.props.name
  }

  get businessRole(): string {
    return this.props.businessRole
  }

  get routePath(): string {
    return this.props.routePath
  }

}
