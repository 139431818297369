export const AdminCookie = '_oPlkkHa';

export enum Access {
  PUBLIC = '1',
  REGISTERED = '2',
}

export enum SearchTypes {
  ALL = '0',
  NEWS = '1',
  AREE_TEMATICHE = '2',
  BANCA_DATI = '3',
  ALL_HEADER = '4',
  MASSIMARIO = '5',
  FORMULARI = '6',
  CODICI = '7'
}

export enum Massimario {
  CASSASIONE_CIVILE = '999999590',
  CASSASIONE_PENALE = '999999591',
  CASSASIONE_MERITO = '999999621',
  CORTE_COSTITUZIONALE = '999999656',
  GIUSTIZIA_AMMINISTRATIVA = '999999659',
}

export enum Pubblicazioni {
  RIVISTE = '999999627',
  GUIDE_OPERATIVE = '999999628',
  AIGA_NEWS = '999999663',
  RNF_MAGAZINE = '999999665'
}

export enum Video {
  PILLOLE_VIDEO = '999999661',
  APPROFONDIMENTI = '999999298',
  PODCAST = '999999662',
  WEBINAR = '999999664',
}

export const CategoriesMassimario = [Massimario.CASSASIONE_CIVILE, Massimario.CASSASIONE_PENALE, Massimario.CASSASIONE_MERITO, Massimario.CORTE_COSTITUZIONALE]
export const CategoriesFormulari = ["999999654"]

export enum Categories {
  FORMULARI = '9999996',
  MASSIME = '9999997',
  COMMENTI = '9999998',
  ULTIME_NEWS = '9999999',
  SCHEDA_AUTORALE = '99999910',
  APPROFONDIMENTI = '999999298',
  CODICI = '999999316',
  CONCORSI = '99999911',
  SINTESI_GIURISPRUDENZIALI = '99999912',
  DIRITTO_SOCIETARIO = '99999914',
  DIRITTO_FALLIMENTARE = '999999289',
  DIRITTO_TRIBUTARIO = '99999919',
  DIRITTO_DEL_LAVORO = '99999915',
  DIRITTO_AMMINISTRATIVO = '999999569',
  DIRITTO_BANCARIO = '9999995',
  ASSICURAZIONI = '99999920',
  DIRITTO_FAMIGLIA = '99999921',
  SINTESI_STORICO = '999999291',
  MERCATI_IMMOBILIARI = '999999311',
  PRIVACY = '999999310',
  CONTRATTUALISTICA_DIRITTO_PRIVATO = '999999312',
  BANCARIO_MERCATI_FINANZIARI = '999999313',
  PROCESSO_TELEMATICO = '999999314',
  DIRITTO_PENALE = '999999469',
  CYBERSICUREZZA = '999999317',
  DIRITTO_NUOVE_TECNOLOGIE = '999999493',
  FORMULE = '999999654'
}

export const CategoriesNews = [
  Categories.ULTIME_NEWS,
  Categories.APPROFONDIMENTI,
];
export const CategoriesAreeTematiche = [
  Categories.COMMENTI,
  Categories.SCHEDA_AUTORALE,
  Categories.SINTESI_GIURISPRUDENZIALI,
];

export enum FcmTopicsPlatform {
  PORTAL = 'portal',
  APP = 'app',
}

export enum FcmTopicsAreeTematiche {
  GENERALE = 'generale',
  DIRITTO_SOCIETARIO = 'diritto-societario',
  DIRITTO_FALLIMENTARE = 'diritto-fallimentare',
  DIRITTO_PENALE = 'diritto-penale',
  DIRITTO_TRIBUTARIO = 'diritto-tributario',
  DIRITTO_DEL_LAVORO = 'diritto-del-lavoro',
  DIRITTO_AMMINISTRATIVO = 'diritto-amministrativo',
  ASSICURAZIONI = 'assicurazioni',
  DIRITTO_DI_FAMIGLIA = 'diritto-famiglia',
  MERCATI_IMMOBILIARI = 'mercati-immobiliari',
  PRIVACY = 'privacy',
  CONTRATTUALISTICA_DIRITTO_PRIVATO = 'contrattualistica-diritto-privato',
  BANCARIO_MERCATI_FINANZIARI = 'bancario-mercati-finanziari',
  PROCESSO_TELEMATICO = 'processo-telematico',
  CYBERSICUREZZA = 'cybersicurezza',
  DIRITTO_NUOVE_TECNOLOGIE = "diritto-nuove-tecnologie"
}
export const FcmTopicsAreeTematicheList = [
  FcmTopicsAreeTematiche.GENERALE,
  FcmTopicsAreeTematiche.DIRITTO_SOCIETARIO,
  FcmTopicsAreeTematiche.DIRITTO_FALLIMENTARE,
  FcmTopicsAreeTematiche.DIRITTO_PENALE,
  FcmTopicsAreeTematiche.DIRITTO_TRIBUTARIO,
  FcmTopicsAreeTematiche.DIRITTO_DEL_LAVORO,
  FcmTopicsAreeTematiche.DIRITTO_AMMINISTRATIVO,
  FcmTopicsAreeTematiche.ASSICURAZIONI,
  FcmTopicsAreeTematiche.DIRITTO_DI_FAMIGLIA,
  FcmTopicsAreeTematiche.MERCATI_IMMOBILIARI,
  FcmTopicsAreeTematiche.PRIVACY,
  FcmTopicsAreeTematiche.CONTRATTUALISTICA_DIRITTO_PRIVATO,
  FcmTopicsAreeTematiche.BANCARIO_MERCATI_FINANZIARI,
  FcmTopicsAreeTematiche.PROCESSO_TELEMATICO,
  FcmTopicsAreeTematiche.CYBERSICUREZZA,
  FcmTopicsAreeTematiche.DIRITTO_NUOVE_TECNOLOGIE
];
interface CategoriesToRoutesInterface {
  [catId: string]: string;
}
export const CategoriesToRoutes: CategoriesToRoutesInterface = {};
CategoriesToRoutes['99999914'] = 'diritto-societario';
CategoriesToRoutes['999999289'] = 'diritto-fallimentare';
CategoriesToRoutes['999999469'] = 'diritto-penale';
CategoriesToRoutes['99999919'] = 'diritto-tributario';
CategoriesToRoutes['99999915'] = 'diritto-del-lavoro';
CategoriesToRoutes['999999569'] = 'diritto-amministrativo';
CategoriesToRoutes['99999920'] = 'assicurazioni';
CategoriesToRoutes['99999921'] = 'diritto-famiglia';
CategoriesToRoutes['999999311'] = 'mercati-immobiliari';
CategoriesToRoutes['999999310'] = 'privacy';
CategoriesToRoutes['999999312'] = 'contrattualistica-diritto-privato';
CategoriesToRoutes['999999313'] = 'bancario-mercati-finanziari';
CategoriesToRoutes['999999314'] = 'processo-telematico';
CategoriesToRoutes['999999317'] = 'cybersicurezza';
CategoriesToRoutes['999999493'] = 'diritto-nuove-tecnologie';

interface RoutesToCategoriesInterface {
  [route: string]: string;
}
export const RoutesToCategories: RoutesToCategoriesInterface = {};
RoutesToCategories['diritto-societario'] = Categories.DIRITTO_SOCIETARIO;
RoutesToCategories['diritto-penale'] = Categories.DIRITTO_PENALE;
RoutesToCategories['diritto-fallimentare'] = Categories.DIRITTO_FALLIMENTARE;
RoutesToCategories['diritto-tributario'] = Categories.DIRITTO_TRIBUTARIO;
RoutesToCategories['diritto-del-lavoro'] = Categories.DIRITTO_DEL_LAVORO;
RoutesToCategories['diritto-amministrativo'] =
  Categories.DIRITTO_AMMINISTRATIVO;
RoutesToCategories['assicurazioni'] = Categories.ASSICURAZIONI;
RoutesToCategories['diritto-famiglia'] = Categories.DIRITTO_FAMIGLIA;
RoutesToCategories['mercati-immobiliari'] = Categories.MERCATI_IMMOBILIARI;
RoutesToCategories['privacy'] = Categories.PRIVACY;
RoutesToCategories['contrattualistica-diritto-privato'] =
  Categories.CONTRATTUALISTICA_DIRITTO_PRIVATO;
RoutesToCategories['bancario-mercati-finanziari'] =
  Categories.BANCARIO_MERCATI_FINANZIARI;
RoutesToCategories['processo-telematico'] = Categories.PROCESSO_TELEMATICO;
RoutesToCategories['cybersicurezza'] = Categories.CYBERSICUREZZA;
RoutesToCategories['diritto-nuove-tecnologie'] = Categories.DIRITTO_NUOVE_TECNOLOGIE;

interface RoutesToNamesInterface {
  [route: string]: string;
}
export const RoutesToNames: RoutesToNamesInterface = {};
RoutesToNames['diritto-societario'] = 'Diritto Societario';
RoutesToNames['diritto-penale'] = 'Diritto Penale';
RoutesToNames['diritto-fallimentare'] = 'Diritto Fallimentare';
RoutesToNames['diritto-tributario'] = 'Diritto Tributario';
RoutesToNames['diritto-del-lavoro'] = 'Diritto del Lavoro';
RoutesToNames['diritto-amministrativo'] = 'Diritto Amministrativo';
RoutesToNames['assicurazioni'] = 'Assicurazione e Risarcimento Danni';
RoutesToNames['diritto-famiglia'] = 'Diritto di Famiglia e Successioni';
RoutesToNames['mercati-immobiliari'] = 'Mercati immobiliari';
RoutesToNames['privacy'] = 'Protezione dei dati personali';
RoutesToNames['contrattualistica-diritto-privato'] =
  'Contrattualistica di diritto privato';
RoutesToNames['bancario-mercati-finanziari'] = 'Bancario e mercati finanziari';
RoutesToNames['processo-telematico'] = 'Diritto Processuale Telematico';
RoutesToNames['cybersicurezza'] = 'Cybersicurezza';
RoutesToNames['diritto-nuove-tecnologie'] = 'Diritto delle nuove tecnologie';

export enum EmailTemplates {
  CONTATTO = 'contatto',
  ATTIVAZIONE = 'attivazione',
  REGISTRAZIONE = 'registrazione',
  ATTIVAZIONE_PASSWORD = 'attivazione-password',
  RICHIESTA_TRIAL = 'richiesta-trial',
  SOTTOSCRIZIONE_NEWSLETTER = 'sottoscrizione-newsletter',
  RICHIESTA_ABBONAMENTO = 'richiesta-abbonamento',
}

export enum CategoriesJuranet {
  // Documentazione - Normativa nazionale"
  DLS = '16',
  // Documentazione - Normativa regionale"
  DLR = '34',
  // Tribunale UE - Consiglio nazionale forense"
  CGDU = '71',
  // Prassi e decisioni"
  PA = '20',
  // Giurisprudenza - Merito"
  TDA = '40',
  // Giurisprudenza - TAR"
  TAR = '28',
  // Documentazione - Normativa europea"
  NC = '17',
  // Documentazione - Normativa internazionale"
  AI = '18',
  // Giurisprudenza - Consiglio di Stato"
  DCCS = '27',
  // Giurisprudenza - Corte costituzionale"
  DCCOST = '26',
  // Giurisprudenza - Corte di Giustizia UE"
  DCCG = '13',
  // Tribunale UE - Corte dei Conti"
  DCCC = '69',
  // Giurisprudenza - Corte di Cassazione"
  DCCASS = '19',
  // Giurisprudenza - Tribunale UE - Commissioni tributarie"
  CT = '70',
}

export const CategoriesJuranews = [
  '9999998',
  '9999999',
  '99999910',
  '99999911',
  '99999912',
];

export const CategoriesNormativa = ['16', '17', '18', '34'];

export const CategoriesGiurisprudenza = ['26', '19', '27', '28', '13', '40'];

export const CategoriesPrassiJuranet = [
  '43',
  '44',
  '53',
  '57',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '72',
  '73',
  '74',
  '75',
  '76',
];

export enum Sections {
  BANCA_DATI = '1000',
  COMMON_SEARCH_RESULT = '1001',
  REAL_TIME = '1002',
  CODICI = '1003',
  MASSIMARIO = '1004',
  PUBBLICAZIONI = '1005',
  VIDEO = '1006',
  AI_CHAT = '1007',
  AI_REVIEW = '1008'
}

export enum OrderingTypes {
  SCORE = '0',
  DATA_CREAZIONE = '1',
  DATA_GAZZETTA = '2',
}
