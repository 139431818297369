<template>
  <div class="ma-0 pa-0">
    <vue-simple-suggest class="ma-0 pa-0" v-model="selected" :list="suggestions" :filter-by-query="true" :styles="autoCompleteStyle"
      :min-length="3" :max-suggestions="10" @select="selectText" @input="selectText" @click.stop
      @keydown.enter="checkAndSearch" placeholder="Inserisci il testo da ricercare">
    </vue-simple-suggest>

    <v-snackbar v-model="alert" :timeout="-1" top>
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn class="red-juranews white--text" text v-bind="attrs" @click="alert = false">
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="alertSpellCorrection" timeout="-1" top>
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          {{ alertSpellCorrectionMessage }}
        </v-col>
        <v-col cols="1" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <v-col cols="3">
          <v-btn class="red-juranews text-h7 elevation-0 rounded-0 mr-2" text width="120" @click="acceptSpellCheck()">
            Accetta
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn class="red-juranews text-h7 elevation-0 rounded-0 " text width="120" @click="refuseSpellCheck()">
            Rifiuta
          </v-btn>
        </v-col>
        <v-col cols="3" />
      </v-row>
    </v-snackbar>
  </div>
</template>

<script lang="js">
import { Component, Prop, Vue } from "vue-property-decorator";
import Autocomplete from 'v-autocomplete'
import VueSimpleSuggest from 'vue-simple-suggest'
import { JnService } from '@/services/jn.service';
//import 'v-autocomplete/dist/v-autocomplete.css'
import 'vue-simple-suggest/dist/styles.css'
import EventBus from '@/services/event.bus';
import {
  SearchTypes
} from '@/models/enums.model';

@Component({
  components: { VueSimpleSuggest },
})
export default class JnAutocompleteSearch extends Vue {

  @Prop({ required: true })
  searchScope;

  @Prop({ required: false })
  index;

  selected = ''
  selectedCorrected = ''
  item = ''
  items = []
  suggestions = []

  alert = false;
  alertMessage = '';
  alertSpellCorrection = false;
  alertSpellCorrectionMessage = '';

  autoCompleteStyle = {
    vueSimpleSuggest: "",
    inputWrapper: "",
    defaultInput: "",
    suggestions: "suggestion z-index",
    suggestItem: "suggestItem"
  }

  getLabel(item) {
    console.log("getLabel: " + item.phrase)
    if (item) {
      return item.phrase
    }
    return ''
  }


  filter(text) {
    console.log("JnAutocompleteSearch.update(text): " + text)
    if (text.length > 3) {
      this.items = this.items.data.filter((item) => {
        console.log("JnAutocompleteSearch.update(item): " + item.phrase)
        return (new RegExp(text.toLowerCase())).test(item.phrase.toLowerCase())
      })
    }
    else {
      this.items = []
    }
  }

  async created() {
    EventBus.$on('update-autocomplete-text', (suggestion) => {
      console.log(
        'JnAutocompleteSearch.created(select-text): ' +
        JSON.stringify(suggestion)
      );
      this.selected = suggestion.suggestion;
    });

    EventBus.$on('check-spell', async (searchScope) => {
      console.debug('JnAutocompleteSearch.on(check-spell)(searchScope): ' + searchScope);
      if (this.searchScope == searchScope) {
        if (this.selected) {
          this.selectedCorrected = await JnService.spellCheck(this.selected);
          console.log('JnAutocompleteSearch.selectedCorrected: ' + this.selectedCorrected);
          if (this.selectedCorrected != this.selected) {
            this.alertSpellCorrectionMessage =
              "Sono stati rilevati possibili errori nelle chiavi di ricerca. Il testo suggerito è: '" +
              this.selectedCorrected +
              "'";
            this.alertSpellCorrection = true;
          } else {
            this.selectedCorrected = '';
            EventBus.$emit('search', {
              searchScope: this.searchScope,
              keywords: this.selected,
              index: this.index
            });
          }
        } else {
          this.alertMessage =
            "E' necessario indicare almeno una keyword di ricerca";
          this.alert = true;
          this.loading = false;
        }
      }
    });

    EventBus.$on('clear-keywords', () => {
      this.selected = ''
    });

    const items = await JnService.getAutocompleteSearch();

    for (const item of items) {
      this.suggestions.push(item.phrase)
    }
  }

  selectText(event) {
    EventBus.$emit('select-text', { searchScope: this.searchScope, suggestion: event, index: this.index });
  }

  checkAndSearch() {
    EventBus.$emit('check-spell', SearchTypes.ALL);
  }

  async acceptSpellCheck() {
    this.selected = this.selectedCorrected;
    EventBus.$emit('search', {
      searchScope: this.searchScope,
      keywords: this.selected,
      index: this.index
    });
    this.alertSpellCorrection = false
  }

  async refuseSpellCheck() {
    EventBus.$emit('search', {
      searchScope: this.searchScope,
      keywords: this.selected,
      index: this.index
    });
    this.alertSpellCorrection = false
  }

}
</script>
<style lang="scss">
.z-index {
  z-index: 9 !important;
}

.suggestion {
  background-color: white !important;
  color: black !important;
  opacity: 100 !important;
  position: absolute !important;
  z-index: 9 !important;
}

.vue-simple-suggest.designed .suggestions .suggest-item.hover {
  background-color: #e6bec0 !important;
  color: #e62530 !important;
  position: relative !important;
  z-index: 9 !important;
}

.vue-simple-suggest.designed .suggestions .suggest-item.selected {
  background-color: #e62530 !important;
  color: white !important;
  position: relative !important;
  z-index: 9 !important;
}

.default-input {
  padding: 5px !important;
  background-color: #424242 !important;
  border-top-color: #bbbbbb !important;
  border-top-style: solid !important;
  border-top-width: 1px !important;
  border-right-color: #bbbbbb !important;
  border-right-style: solid !important;
  border-right-width: 1px !important;
  border-bottom-color: #bbbbbb !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-left-color: #bbbbbb !important;
  border-left-style: solid !important;
  border-left-width: 1px !important;
  color: white !important;
}
</style>
