
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Menu } from '@/models/menu.model';
import JnSubMenuItem from '@/views/home/SubMenuItem.vue';

@Component({
  components: { JnSubMenuItem },
})
export default class JnDotsMenu extends Vue {
  @Prop({ required: true, default: '' })
  item!: Menu;

  menu = false;

  goToSub(item: any) {
    this.$router.push(item.routePath);
  }
}
