export class User {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  profession: string;
  firstContact: string;
  mobileNumber: string;
  city: string;
  country: string;
  address: string;
  cap: string;
  state: string;
  password?: string;
  codiceFiscale: string;
  partitaIva: string;
  codiceDestinatario: string;
  emailPec: string;
  admin: boolean

  constructor(
    id: string,
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    profession: string,
    firstContact: string,
    mobileNumber: string,
    city: string,
    country: string,
    address: string,
    cap: string,
    state: string,
    password: string | undefined,
    codiceFiscale: string,
    partitaIva: string,
    codiceDestinatario: string,
    emailPec: string,
    admin: boolean
  ) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.profession = profession;
    this.firstContact = firstContact;
    this.mobileNumber = mobileNumber;
    this.city = city;
    this.country = country;
    this.address = address;
    this.cap = cap;
    this.state = state;
    this.password = password;
    this.codiceFiscale = codiceFiscale;
    this.partitaIva = partitaIva;
    this.codiceDestinatario = codiceDestinatario;
    this.emailPec = emailPec;
    this.admin = admin;
  }
}
