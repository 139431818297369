interface ServiceProps {
  id: string;
  seq: number;
  imageSrc: string;
  title: string;
  description: string;
  routePath: string;
}

export class Service {

  private props: ServiceProps

  constructor(props: ServiceProps) {
    this.props = props
  }

  // GETTERs and SETTERs
  get id(): string {
    return this.props.id
  }

  get seq(): number {
    return this.props.seq
  }

  get description(): string {
    return this.props.description
  }

  get title(): string {
    return this.props.title
  }

  get routePath(): string {
    return this.props.routePath
  }

  get imageSrc(): string {
    return this.props.imageSrc
  }

}
