import { User } from '@/models/crm/user.model';
import { Service } from '@/models/crm/service.model';

export class Purchase {
  subject: string;
  serviceId: string;
  contactId: string;
  country: string;
  address: string;
  zip: string;
  state: string;
  city: string;
  user?: User;
  service?: Service;
  scadenza?: string;
  status?: string;
  maxNumSessions: number;

  constructor(
    subject: string,
    serviceId: string,
    contactId: string,
    country: string,
    address: string,
    zip: string,
    state: string,
    city: string,
    user: User | undefined,
    service: Service | undefined,
    scadenza: string | undefined,
    status: string | undefined,
    maxNumSessions: number
  ) {
    this.subject = subject;
    this.serviceId = serviceId;
    this.contactId = contactId;
    this.country = country;
    this.address = address;
    this.zip = zip;
    this.state = state;
    this.city = city;
    this.user = user;
    this.service = service;
    this.scadenza = scadenza;
    this.status = status;
    this.maxNumSessions = maxNumSessions
  }
}
