interface PicklistProps {
  value: string;
  label: string;
}

export class Picklist {

  private props: PicklistProps

  constructor(props: PicklistProps) {
    this.props = props
  }

  // GETTERs and SETTERs
  get value(): string {
    return this.props.value
  }
  get label(): string {
    return this.props.label
  }
}
